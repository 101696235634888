import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import ArticlesGrid from "../components/articles-grid"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

const WhatsOnPage = () => {

    const { allStrapiWhatsOn } = useStaticQuery(graphql`
    query {
        allStrapiWhatsOn {
            nodes {
              title
              id
              releaseText {
                data {
                  id
                  releaseText
                }
              }
              poster {
                id
                alternativeText
                localFile {
                  localURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
    }
  `)

    const seo = {
        metaTitle: allStrapiWhatsOn.nodes[0].title,
        metaDescription: allStrapiWhatsOn.nodes[0].releaseText.data.releaseText,
        shareImage: allStrapiWhatsOn.nodes[0].poster,
    }

    return (
        <Layout>
            <div>
                <Seo seo={seo} />
                <div className="container">
                    <div class="font-light text-tpBlue text-4xl border-b-2 border-t-2 border-tpBlue p-2 text-center mt-12">
                        <span class="font-bold">Current:</span> {allStrapiWhatsOn.nodes[0].title}
                    </div>
                    <div class="w-full content-center mt-24">
                        <div class="w-3/4 mx-auto">
                            <GatsbyImage
                                image={getImage(allStrapiWhatsOn.nodes[0].poster?.localFile)}
                            />
                        </div>
                    </div>
                    <div class="mt-10 text-2xl font-light text-tpBlackish tracking-wider leading-10">
                        <ReactMarkdown>{allStrapiWhatsOn.nodes[0].releaseText.data.releaseText}</ReactMarkdown>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default WhatsOnPage